import Logo from './1.jpg'
import Footer1 from './2.png'
import Footer2 from './3.png'
import Footer3 from './4.png'
import Footer4 from './5.png'
import Banner from './banner.jpg'
import Avatar from './avt.jpg'

const Image = {
    Logo,
    Footer1,
    Footer2,
    Footer3,
    Footer4,
    Banner,
    Avatar
}

export default Image