import React from 'react';
import PropTypes from 'prop-types';
import Global from '../Image/Global'
import logo from '../Image/logo.png'

Footer.propTypes = {

};

function Footer(props) {
    return (
        <div className="footer" style={{backgroundColor:'black'}}>
            {/* <div className="footer-static-top">
                <div className="container">
                    <div className="footer-shipping pt-60 pb-55 pb-xs-25">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 pb-sm-55 pb-xs-55">
                                <div className="li-shipping-inner-box">
                                    <div className="shipping-icon">
                                        <img src={Global.Footer1} alt="Shipping Icon" />
                                    </div>
                                    <div className="shipping-text">
                                        <h2>Free Delivery</h2>
                                        <p>And free returns. See checkout for delivery dates.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 pb-sm-55 pb-xs-55">
                                <div className="li-shipping-inner-box">
                                    <div className="shipping-icon">
                                        <img src={Global.Footer2} alt="Shipping Icon" />
                                    </div>
                                    <div className="shipping-text">
                                        <h2>Safe Payment</h2>
                                        <p>Pay with the world's most popular and secure payment methods.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 pb-xs-30">
                                <div className="li-shipping-inner-box">
                                    <div className="shipping-icon">
                                        <img src={Global.Footer3} alt="Shipping Icon" />
                                    </div>
                                    <div className="shipping-text">
                                        <h2>Shop with Confidence</h2>
                                        <p>Our Buyer Protection covers your purchasefrom click to delivery.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 pb-xs-30">
                                <div className="li-shipping-inner-box">
                                    <div className="shipping-icon">
                                        <img src={Global.Footer4} alt="Shipping Icon" />
                                    </div>
                                    <div className="shipping-text">
                                        <h2>24/7 Help Center</h2>
                                        <p>Have a question? Call a Specialist or chat online.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="footer-static-middle">
                <div className="container">
                    <div className="footer-logo-wrap pt-50 pb-35">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="footer-logo">
                                    <img src={logo} style={{ width: '20rem'}} alt="Footer Logo" />
                                    <p className="info">
                                       Nhận Order Hàng Mỹ, Uy Tín, Chất Lượng.
                                       Chuyên Săn Sale Hàng Chính Hãng, Giá Rẻ, Nguồn Gốc Rõ Ràng.
                                </p>
                                </div>
                                <ul className="des">
                                    <li>
                                        <span>Phone: </span>
                                        <a href="#">0785170022</a>
                                    </li>
                                    <li>
                                        <span>Email: </span>
                                        <a href="mailto://vuthituuyen2709@gmail.com">vuthituuyen2709@gmail.com</a>
                                    </li>
				      <li>
                                        <span>Facebook: </span>
                                        <a href="https://www.facebook.com/profile.php?id=100000805803158">Tú Uyên</a>
                                    </li>
	                               <li>
                                        <span>Facebook Group: </span>
                                        <a href="https://www.facebook.com/groups/4341176329253179/">Nhóm Săn Sale</a>
                                    </li>
				    <li>
                                        <span>Facebook Page: </span>
                                        <a href="https://www.facebook.com/orderhangmy247">Orderhagmy247 Fanpage</a>
                                    </li>



	    
                                </ul>
                            </div>
	    	                     {/*
                                <div className="col-lg-2 col-md-3 col-sm-6">
                                    <div className="footer-block">
                                        <h3 className="footer-block-title">Product</h3>
                                        <ul>
                                            <li><a href="#">Prices drop</a></li>
                                            <li><a href="#">New products</a></li>
                                            <li><a href="#">Best sales</a></li>
                                            <li><a href="#">Contact us</a></li>
                                        </ul>
                                    </div>
                                </div>
                            <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="footer-block">
                                    <h3 className="footer-block-title">Our company</h3>
                                        <ul>
                                            <li><a href="#">Delivery</a></li>
                                            <li><a href="#">Legal Notice</a></li>
                                            <li><a href="#">About us</a></li>
                                            <li><a href="#">Contact us</a></li>
                                      </ul>
                                </div>
                            </div>*/}
                            <div className="col-lg-6">  
                                <div className="footer-newsletter">
                                    <h4>Sign up to newsletter</h4>
                                    <form action="#" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="footer-subscribe-form validate" target="_blank">
                                        <div id="mc_embed_signup_scroll">
                                            <div id="mc-form" className="mc-form subscribe-form form-group" >
                                                <input id="mc-email" type="email" autoComplete="off" placeholder="Enter your email" />
                                                <button className="btn" id="mc-submit">Subscribe</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
