import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string'
import Product from '../API/Product';
import { Link, useParams } from 'react-router-dom';
import Products from './Component/Products';
import Pagination from './Component/Pagination';
import Search from './Component/Search';
import SaleAPI from '../API/SaleAPI';
Costco.propTypes = {

};

function Costco(props) {

    const { id } = useParams()

    const [products, setProducts] = useState([])
    const [showScroll, setShowScroll] = useState(false);
    //Tổng số trang
    const [totalPage, setTotalPage] = useState()


    const [sales, setSales] = useState([])

    //Từng trang hiện tại
    const [pagination, setPagination] = useState({
        page: '1',
        count: '36',
        search: '',
        category: id
    })

    useEffect(() => {
        const fetchData = async () => {
            const resDetail = await SaleAPI.getList()
            console.log(resDetail);
           
            setSales(resDetail)
        }

        fetchData()
    },[  pagination])

     

    // const intersection = products.filter(product => 
    //     this.sales.some(sale => product._id === sale._id)
    //     );
    // console.log('intersection' + intersection)

    

    //Hàm này dùng để thay đổi state pagination.page
    //Nó sẽ truyền xuống Component con và nhận dữ liệu từ Component con truyền lên
    const handlerChangePage = (value) => {
        console.log("Value: ", value)

        //Sau đó set lại cái pagination để gọi chạy làm useEffect gọi lại API pagination
        setPagination({
            page: value,
            count: pagination.count,
            search: pagination.search,
            category: pagination.category
        })
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [pagination.page, id]);

       // Scroll to top when clicking the arrow
       const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

   // useEffect(() => {
    //    const fetchData = async () => {
   //         const resDetail = await SaleAPI.getList();
   //         console.log(resDetail);
    //        setSales(resDetail);
    //    };
  //      fetchData();
  //  }, [pagination]);

    //Gọi hàm để load ra sản phẩm theo pagination dữ vào id params 
    useEffect(() => {

        const fetchData = async () => {

            const params = {
                page: pagination.page,
                count: pagination.count,
                search: pagination.search,
                category: id
            }

            const query = '?' + queryString.stringify(params)

            const response = await Product.Get_Pagination(query)
            console.log(response)

            await setProducts(response)


            // Gọi API để tính tổng số trang cho từng loại sản phẩm
            const params_total_page = {
                id_category: id
            }

            const query_total_page = '?' + queryString.stringify(params_total_page)

            const response_total_page = await Product.Get_Category_Product(query_total_page)

            //Tính tổng số trang = tổng số sản phẩm / số lượng sản phẩm 1 trang
            const totalPage = Math.ceil(parseInt(response_total_page.length) / parseInt(pagination.count))
            console.log(totalPage)

            await setTotalPage(totalPage)

        }

        fetchData()

    }, [id])

    //Gọi hàm để load ra sản phẩm theo pagination dữ vào id params 
    useEffect(() => {
        const fetchData = async () => {
            const params = {
                page: pagination.page,
                count: pagination.search ? 200 : pagination.count, // Load all products if searching
                search: pagination.search,
                category: id
            };
    
            const query = '?' + queryString.stringify(params);
    
            const response = await Product.Get_Pagination(query);
            console.log(response);
    
            setProducts(response);
    
            if (!pagination.search) { // Only calculate total pages if not searching
                const params_total_page = {
                    id_category: id
                };
    
                const query_total_page = '?' + queryString.stringify(params_total_page);
    
                const response_total_page = await Product.Get_Category_Product(query_total_page);
    
                const totalPage = Math.ceil(parseInt(response_total_page.length) / parseInt(pagination.count));
                console.log(totalPage);
    
                setTotalPage(totalPage);
            }
        };
    
        fetchData();
    }, [pagination, id]);


    const [male, set_male] = useState([])
    const [female, set_female] = useState([])
    const [costco, set_costco] = useState([])

    // Gọi API theo phương thức GET để load category
    useEffect(() => {

        const fetchData = async () => {

            // gender = male
            const params_male = {
                gender: 'costco'
            }

            const query_costco = '?' + queryString.stringify(params_male)

            const response_male = await Product.Get_Category_Gender(query_costco)

            await set_costco(response_male)

    
        }

        fetchData()

    }, [])

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            page: '1',
            search: '',
            category: id,
        }));
    }, [id]);


    const handler_Search = (value) => {
     
        setPagination({
            page: pagination.page,
            count: 50,
            search: value,
            category: pagination.category
        })

    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowScroll(true); // Show button after scrolling down
            } else {
                setShowScroll(false); // Hide button near the top
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // const handleCatalogChange = async (e)=> {
    //     var id = e.target.value; 
    //     setPagination({
    //         page: '1',
    //         count: '30',
    //         search: '',
    //         category: id
    //     })

    //     const params = {
    //         page: '1',
    //         count: pagination.count,
    //         search: pagination.search,
    //         category: id
    //     }

    //     const query = '?' + queryString.stringify(params)

    //     const response = await Product.Get_Pagination(query)
    //     console.log(response)

    //     await setProducts(response)

    //     // Gọi API để tính tổng số trang cho từng loại sản phẩm
    //         const params_total_page = {
    //             id_category: id
    //         }

    //         const query_total_page = '?' + queryString.stringify(params_total_page)

    //         const response_total_page = await Product.Get_Category_Product(query_total_page)

    //         //Tính tổng số trang = tổng số sản phẩm / số lượng sản phẩm 1 trang
    //         const totalPage = Math.ceil(parseInt(response_total_page.length) / parseInt(pagination.count))
    //         console.log(totalPage)

    //         await setTotalPage(totalPage)
    // }




    return (
        <div>
            
 
            <div className="li-main-blog-page li-main-blog-details-page pt-60 pb-60 pb-sm-45 pb-xs-45" style={{backgroundColor:'#f2f2f2'}}>
                <div className="container-fluid" style={{width:'90%'}}>
                    <div className="row">
                        <div className="col-lg-2 order-lg-1 order-2" style={{backgroundColor:'#f2f2f2'}}>
                            <div className="li-blog-sidebar-wrapper">
                                <div className="li-blog-sidebar pt-25">
                                    <h4 className="li-blog-sidebar-title">All Product</h4>
                                    <ul className="li-blog-archive">
                                        <li><Link to="/shop/all" style={id === 'all' ? { cursor: 'pointer', color: '#fed700' } : { cursor: 'pointer' }}>All</Link></li>
                                    </ul>
                                </div>
                                {/* <div className="li-blog-sidebar">
                                    <h4 className="li-blog-sidebar-title">Female</h4>
                                    <ul className="li-blog-archive">
                                        {
                                            female && female.map(value => (
                                                <li key={value._id}>
                                                    <Link onClick={handleCatalogChange} style={id === value._id ? { cursor: 'pointer', color: '#fed700' } : { cursor: 'pointer' }}>{value.category}</Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div> */}
                                <div className="li-blog-sidebar">
                                    <h4 className="li-blog-sidebar-title">Costco</h4>
                                    <ul className="li-blog-archive">
                                        {
                                            costco && costco.map(value => (
                                                <li key={value._id}>
                                                    <Link to={`/costco/${value._id}`} style={id === value._id ? { cursor: 'pointer', color: '#fed700' } : { cursor: 'pointer' }}>{value.category}</Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                           
                               
                            </div>
                        </div>
                        <div className="col-lg-10 order-1 order-lg-2">
                            <div className="shop-products-wrapper"  >
                                <div className="tab-content">
                                    <div id="grid-view" className="tab-pane active" role="tabpanel">
                                        <div className="product-area shop-product-area">
                                            <Products products={products} sales={sales} />
                                        </div>
                                    </div>
                                    <div className="paginatoin-area">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <p>Showing 1-9 of 30 item(s)</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="shop-top-bar">
                            {pagination.search ? null : (
                                  <div className="product-select-box" style={{ margin: '0 auto' }}>
                                         <Pagination pagination={pagination} handlerChangePage={handlerChangePage} totalPage={totalPage} />
                                 </div>
                             )}
</div>
                        </div>
                    </div>
                </div>
            </div>
        
            {showScroll && (
                <div
                    onClick={scrollToTop}
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '10px',
                        cursor: 'pointer',
                        backgroundColor: 'lightblue',
                        borderRadius: '50%',
                        padding: '10px',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
                    }}
                >
                    ↑↑
                </div>
            )}
        </div>
    );
}

export default Costco;
