// import axiosClient from './axiosClient'

// const Cart = {

//     Get_Cart: (query) => {
//         const url = `/api/Cart${query}`
//         return axiosClient.get(url)
//     },

//     Post_Cart: (data) => {
//         const url = '/api/Cart'
//         return axiosClient.post(url, data)
//     },

//     Put_Cart: (query) => {
//         const url = `/api/Cart${query}`
//         return axiosClient.put(url)
//     },

//     Delete_Cart: (id) => {
//         const url = `/api/Cart/${id}`
//         return axiosClient.delete(url)
//     }

// }

// export default Cart