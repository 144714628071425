 
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SaleAPI from '../../API/SaleAPI';
import React, { useEffect, useState } from 'react';

Products.propTypes = {
    products: PropTypes.array,
    sort: PropTypes.string
};

Products.defaultProps = {
    products: [],
    sort: ''
}




// function checkSale(){
// const resDetail =   SaleAPI.checkSale(id)
// if (resDetail.msg === "Thanh Cong"){
//     setSale(resDetail.sale)
// }
 

// }
function Products(props) {
 

    const { products, sort ,sales} = props

    function applyPromotion(price, promotion) {
        return price - (price * (promotion / 100));
      }

    //  console.log('products '+JSON.stringify(products));
      
      // Create a new array for products with promotions applied
      let productsWithPromotions = products.map(product => {
        
     
        // Find if the current product has a related sale
          let relatedSale = sales.filter(sale => sale.id_product._id === product._id);
       //  console.log('relatedSale '+ JSON.stringify(relatedSale[0].promotion)) ;
       
       if (relatedSale.length ===1) {
          // If there's a promotion, apply it to the product's price
       
          return {
            ...product, // keep the existing product properties
            originalPrice: product.price_product, // store the original price
            price_product: applyPromotion(product.price_product, relatedSale[0].promotion) // calculate the new price
          };
        } else {
          // If there's no promotion, return the original product
          return product;
        }
      });
      console.log('productsWithPromotions' + JSON.stringify(productsWithPromotions));

    if (sort === 'DownToUp') {
        products.sort((a, b) => {
            return a.price_product - b.price_product
        });
    }
    else if (sort === 'UpToDown') {
        products.sort((a, b) => {
            return b.price_product - a.price_product
        });
    }

    return (
        <div className="row">
           
            {
                productsWithPromotions && productsWithPromotions.map(value => (
                 
                    <div className="col-lg-2 col-md-2 col-sm-6 mt-40 animate__animated animate__zoomIn col_product" key={value._id}>
                        <div className="single-product-wrap">
                            <div className="product-image">
                                <Link to={`/detail/${value._id}`}>
                                    <img src={value.image} alt="Li's Product Image" />
                                </Link>
                             
                            </div>
                            <div className="product_desc">
                                <div className="product_desc_info">
                                    <div className="product-review">
                                        <h5 className="manufacturer">
                                            <a href="product-details.html">{value.name_product}</a>
                                        </h5>
                                        <div className="rating-box">
                                            <ul className="rating">
                                                <li><i className="fa fa-star-o"></i></li>
                                                <li><i className="fa fa-star-o"></i></li>
                                                <li><i className="fa fa-star-o"></i></li>
                                                <li><i className="fa fa-star-o"></i></li>
                                                <li><i className="fa fa-star-o"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h4><a className="product_name" href="single-product.html">{value.value}</a></h4>
                                 
                                  
                                    <div className="price-box pt-20"> 
                                        {
                                            (value.originalPrice) ? (<div><del className="new-price " style={{ color: '#525252'}}>{new Intl.NumberFormat('vi-VN',{style: 'decimal',decimal: 'VND'}).format(value.originalPrice)+ ' VNĐ'}</del> 
                                              <br />
                                              <del> <span className="new-price new-price-2">{new Intl.NumberFormat('vi-VN',{style: 'decimal',decimal: 'VND'})
                                            .format(parseInt(value.price_product) ) + ' VNĐ'}</span></del>
                                            </div>) :
                                            (<div><del><span className="new-price">{new Intl.NumberFormat('vi-VN',{style: 'decimal',decimal: 'VND'}).format(value.price_product)+ ' VNĐ'}</span>
                                          </del> <h6 style={{ color: 'red',fontWeight: '500',   fontSize: '0.9em' }}>Giá Sale Liên Hệ</h6></div>
                                            )
                                        }
                                        <br />
                                        {/* {
                                            (value.price_product) && (
                                                <span className="new-price new-price-2">{new Intl.NumberFormat('vi-VN',{style: 'decimal',decimal: 'VND'})
                                                .format(parseInt(value.price_product) ) + ' VNĐ'}</span>
                                            )
                                        }  */}
                                    </div>
                                    {/* <div className="price-box">
                                        <span className="new-price">{new Intl.NumberFormat('vi-VN',{style: 'decimal',decimal: 'VND'}).format(value.price_product)+ ' VNĐ'}</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default Products;